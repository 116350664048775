import classNames from 'classnames'
import { Link } from 'gatsby'
import React from 'react'

import { Container } from 'components/Container'
import { Logo } from 'components/Logo'

export const Header: React.FC = () => (
  <header
    className={classNames('bg-white', 'border-gray-100', 'border-b-2', 'py-9')}
  >
    <Container>
      <div className={classNames('flex', 'justify-between')}>
        <Link to="/">
          <Logo />
        </Link>
        <div className={classNames('hidden lg:block')}>
          <div className={classNames('inline-block', 'mr-3', 'text-sm')}>
            version 1.0.0
          </div>
          <Link
            className={classNames(
              'bg-teal',
              'font-semibold',
              'hover:bg-dark-teal',
              'px-6',
              'py-3',
              'rounded-full',
              'text-sm',
              'text-white',
            )}
            target="_blank"
            to="https://dev.azure.com/infotheekgroep/_git/Branding"
          >
            Source
          </Link>
        </div>
      </div>
    </Container>
  </header>
)

Header.displayName = 'Header'
