export enum Theme {
  Dark = '#000000',
  Light = '#ffffff',
}

export type Props = {
  /**
   * Enum of type Theme in order to define which color theme should be applied
   * to logo
   */
  theme?: Theme
}
