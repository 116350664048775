import classNames from 'classnames'
import React from 'react'

export const Container: React.FC = ({ children }) => (
  <div className={classNames('container', 'mx-auto')}>
    <div className={classNames('px-6')}>{children}</div>
  </div>
)

Container.displayName = 'Container'
