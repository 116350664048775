import classNames from 'classnames'
import { Link } from 'gatsby'
import React from 'react'

import { Container } from 'components/Container'
import { useSitemap } from 'hooks/useSitemap'

export const Footer: React.FC = () => {
  /**
   * Use custom hook to get sitemap structure
   */
  const sitemap = useSitemap()
  /**
   * Return footer component
   */
  return (
    <footer className={classNames('bg-dark-blue', 'pb-8', 'pt-16')}>
      <Container>
        <div className={classNames('flex', 'flex-wrap', '-mx-2')}>
          {Object.entries(sitemap).map(([section, pages]) => (
            <div
              className={classNames(
                'mb-9',
                'px-2',
                'md:w-1/6',
                'w-full',
              )}
              key={section}
            >
              <h3 className={classNames('font-semibold', 'mb-4', 'text-white')}>
                {section}
              </h3>
              <ul>
                {pages.map(({ href, label }) => (
                  <li
                    className={classNames('mb-2', 'text-sm', 'text-white')}
                    key={href}
                  >
                    <Link to={href}>{label}</Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div
          className={classNames('mt-6', 'opacity-50', 'text-sm', 'text-white')}
        >
          © 2021 - Flex IT
        </div>
      </Container>
    </footer>
  )
}

Footer.displayName = 'Footer'
