import React from 'react'

import { App } from 'components/App'

/**
 * Wrap each gatsby page with App component
 */
export const wrapRootElement = ({
  element,
}: {
  element: React.ReactNode
}): JSX.Element => <App>{element}</App>
