import classNames from 'classnames'
import React from 'react'

import { Footer } from 'components/Footer'
import { Header } from 'components/Header'

export const App: React.FC = ({ children }) => (
  <main className={classNames('flex', 'flex-col', 'min-h-screen')}>
    <Header />
    <div className={classNames('flex-auto', 'py-6', 'sm:py-8', 'xl:py-16')}>
      {children}
    </div>
    <Footer />
  </main>
)

App.displayName = 'App'
