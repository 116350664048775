import { graphql, useStaticQuery } from 'gatsby'

import { PageNode, Sitemap } from './types'

export const useSitemap = (): Sitemap => {
  /**
   * Use static query hook to get all markdown pages
   */
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: {fields: frontmatter___order}) {
        edges {
          node {
            frontmatter {
              section
              title
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)
  /**
   * Destruct nested structure safely
   */
  const pages = data?.allMarkdownRemark?.edges ?? []
  /**
   * Reduce pages array to manageable sitemap array
   */
  return pages.reduce((sitemap: Sitemap, page: PageNode) => {
    /**
     * Destruct various properties safely
     */
    const section = page?.node?.frontmatter?.section
    const link = {
      label: page?.node?.frontmatter?.title,
      href: page?.node?.fields?.slug,
    }
    /**
     * Aggregate links to corresponding section
     */
    return {
      ...sitemap,
      [section]: (sitemap[section] || []).concat(link),
    }
  }, {})
}
