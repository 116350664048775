import React from 'react'

import { Props, Theme } from './types'

export const Logo: React.FC<Props> = ({ theme = Theme.Dark}) => (
  <svg
    width="123px"
    height="24px"
    viewBox="0 0 123 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polyline
      fill={theme}
      points="41.8124215 0.0363002876 61.988251 0.0363002876 61.988251 6.16910424 49.1852692 6.16910424 49.1852692 8.93408419 61.988251 8.93408419 61.988251 15.0672123 49.1852692 15.0672123 49.1852692 17.8325163 61.988251 17.8325163 61.988251 23.9656444 41.8124215 23.9656444 41.8124215 0.0363002876"
    />
    <polyline
      fill={theme}
      points="0 0.0363002876 18.4998582 0.0363002876 18.4998582 6.16910424 7.37284771 6.16910424 7.37284771 8.93408419 18.4998582 8.93408419 18.4998582 15.0672123 7.37284771 15.0672123 7.37284771 23.9656444 0 23.9656444 0 0.0363002876"
    />
    <polyline
      fill={theme}
      points="21.1676052 0.0363002876 28.5404529 0.0363002876 28.5404529 17.8325163 39.1310619 17.8325163 39.1310619 23.9656444 21.1676052 23.9656444 21.1676052 0.0363002876"
    />
    <polygon
      fill="#11A182"
      points="91.3380059 23.9659685 98.7115018 23.9659685 98.7115018 0.0363002876 91.3380059 0.0363002876"
    />
    <polyline
      fill="#11A182"
      points="108.094802 6.16975246 101.392861 6.16975246 101.392861 0.0363002876 122.171535 0.0363002876 122.171535 6.16975246 115.467974 6.16975246 115.467974 23.9656444 108.094802 23.9656444 108.094802 6.16975246"
    />
    <polyline
      fill={theme}
      points="81.8830774 11.9995138 76.6694486 6.78588502 76.6684763 6.78620913 69.8829154 0 64.6689624 5.21362881 71.4551716 11.9998379 64.6689624 18.7863712 69.8829154 24 76.6684763 17.2137909 76.6694486 17.2144391 81.8830774 12.0008103 81.8824292 11.9998379 81.8830774 11.9995138"
    />
    <polyline
      fill="#11A182"
      points="78.183041 18.7283555 83.397318 13.5144026 88.6534052 18.7708139 83.4397764 23.9847668 78.183041 18.7283555"
    />
    <polyline
      fill="#11A182"
      points="83.3969939 10.4703642 78.183041 5.2567354 83.4401005 0 88.6534052 5.21395292 83.3969939 10.4703642"
    />
  </svg>
)

Logo.displayName = 'Logo'